import { gql } from "@apollo/client";

import { RemoveGameMutation, RemoveGameMutationVariables } from "@/gql";

import { GAME_FRAGMENT } from "../fragments/game.fragment";
import { makeGqlMutationHook } from "../util";

export const REMOVE_GAME = gql`
    ${GAME_FRAGMENT}

    mutation removeGame($gameIds: [String!]!) {
        result: softRemoveGame(gameIds: $gameIds) {
            ...Game
        }
    }
`;

export const useSoftRemoveGameMutation = makeGqlMutationHook<
    RemoveGameMutation,
    RemoveGameMutationVariables
>(REMOVE_GAME);
