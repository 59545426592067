import { gql } from "@apollo/client";

import { CreateTemplateMutation, CreateTemplateMutationVariables } from "@/gql";

import { TEMPLATE_FRAGMENT } from "../fragments/template.fragment";
import { makeGqlMutationHook } from "../util";

const CREATE_TEMPLATE = gql`
    ${TEMPLATE_FRAGMENT}

    mutation createTemplate(
        $consumers: [String!]! = []
        $description: String
        $gameId: String!
        $name: String!
        $type: TemplateType!
    ) {
        result: createTemplate(
            consumers: $consumers
            description: $description
            gameId: $gameId
            name: $name
            type: $type
        ) {
            ...Template
        }
    }
`;

export const useCreateTemplateMutation = makeGqlMutationHook<
    CreateTemplateMutation,
    CreateTemplateMutationVariables
>(CREATE_TEMPLATE);
