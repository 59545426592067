import { gql } from "@apollo/client";

import { CreateGameMutation, CreateGameMutationVariables } from "@/gql";

import { GAME_FRAGMENT } from "../fragments/game.fragment";
import { makeGqlMutationHook } from "../util";

export const CREATE_GAME = gql`
    ${GAME_FRAGMENT}

    mutation createGame($gameName: String!, $description: String!) {
        result: createGame(name: $gameName, description: $description) {
            ...Game
        }
    }
`;

export const useCreateGameMutation = makeGqlMutationHook<
    CreateGameMutation,
    CreateGameMutationVariables
>(CREATE_GAME);
