import { gql } from "@apollo/client";

import { UpdateTemplateMutation, UpdateTemplateMutationVariables } from "@/gql";

import { TEMPLATE_DETAILED_FRAGMENT } from "../fragments/template.fragment";
import { TEMPLATE_CATEGORY_FRAGMENT } from "../fragments/template-category.fragment";
import { makeGqlMutationHook } from "../util";

const UPDATE_TEMPLATE = gql`
    ${TEMPLATE_DETAILED_FRAGMENT}
    ${TEMPLATE_CATEGORY_FRAGMENT}

    mutation updateTemplate(
        $consumers: [String!]! = []
        $description: String
        $gameId: String!
        $guid: String!
        $name: String
        $category: String
    ) {
        result: updateTemplate(
            consumers: $consumers
            description: $description
            gameId: $gameId
            guid: $guid
            name: $name
            category: $category
        ) {
            ...TemplateDetailed
            category {
                ...TemplateCategory
            }
        }
    }
`;

export const useUpdateTemplateMutation = makeGqlMutationHook<
    UpdateTemplateMutation,
    UpdateTemplateMutationVariables
>(UPDATE_TEMPLATE);
